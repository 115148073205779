import React from 'react';
import $ from 'jquery';
import "./sass/main.scss";

// Component Imports
import CustomRedirect from './components/CustomRedirect';

import FooterA from './components/TemplateA/FooterA';
import NavigationBarA from './components/TemplateA/NavigationBarA';
import BannerA from './components/TemplateA/BannerA';
import CardAreaA from './components/TemplateA/CardAreaA';
import TextAreaA from './components/TemplateA/TextAreaA';
import GridAreaA from './components/TemplateA/GridAreaA';
import MapAreaA from './components/TemplateA/MapAreaA';

import GalleryB from './components/TemplateB/GalleryB';
import NavigationBarB from './components/TemplateB/NavigationBarB';
import CardAreaB from './components/TemplateB/CardAreaB';
import FooterB from './components/TemplateB/FooterB';
import BannerB from './components/TemplateB/BannerB';
import FormAreaB from './components/TemplateB/FormAreaB';
import TabbedAreaB from './components/TemplateB/TabbedAreaB';
import GridAreaB from './components/TemplateB/GridAreaB';

import CardAreaC from './components/TemplateC/CardAreaC';
import FooterC from './components/TemplateC/FooterC';
import BannerC from './components/TemplateC/BannerC';
import GalleryC from './components/TemplateC/GalleryC';
import TextAreaC from './components/TemplateC/TextAreaC';
import NavigationBarC from './components/TemplateC/NavigationBarC';
import GridAreaC from './components/TemplateC/GridAreaC';
import MapAreaC from './components/TemplateC/MapAreaC';

import FooterD from './components/TemplateD/FooterD';

class page extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            sections: props.fields.sections ? props.fields.sections : [],
            googleFontImport: props.fields.googleFontImport ? props.fields.googleFontImport : '',
            googleFontName: props.fields.googleFontName ? props.fields.googleFontName : '',
            basicFont: props.fields.basicFont ? props.fields.basicFont : 'sans-serif',
            favicon: props.fields.favicon ? (props.fields.favicon.fields.file ? props.fields.favicon.fields.file.url : '') : '',
            pageTitle: props.fields.pageTitle ? props.fields.pageTitle : document.title,
            floatOnPage: props.fields.floatOnPage ? props.fields.floatOnPage : 'Yes'
        }
    }

    componentDidMount(){
        document.title = this.state.pageTitle;
        $('#favicon').attr('href', this.state.favicon);
        $('meta[name="description"]').attr("content", this.state.metaDescription);
        $('meta[name="keywords"]').attr("content", this.state.metaKeywords);

        if(this.state.floatOnPage==="No"){
            $("#root").css('padding-top',
                $('#root > div > .row').first().attr('class').includes('navbar')?
                    $('#root > div > .row').first().outerHeight()+"px" : ''
            );
        }

        $('a').each(function(i){
            if(!$(this).attr('href').includes(window.location.hostname) && $(this).attr('href').substr(0,1)!=="/"){
                $(this).attr('target', '_blank').attr('rel', 'noopener noreferrer')

                if (!$(this).attr('href').includes('http')) {
                    $(this).attr('href', 'http://' + $(this).attr('href'));
                }
            }
        });
    }

    render(){
        return (
            <div className="container-fluid page-container">
                <style dangerouslySetInnerHTML={{__html: `
                    ${this.state.googleFontImport}
                    body, html{
                        font-family: '${this.state.googleFontName}' , ${this.state.basicFont};
                    }
                `}}></style>
                {
                    this.state.sections&& this.state.sections.map((section, i) => {
                        switch(section.sys.contentType.sys.id){
                            //Custom Redirect
                            case 'customRedirect':
                                return <CustomRedirect key={i} fields={section.fields}/>

                        ///// TEMPLATE A /////

                            //Navigation Bar A
                            case 'navigationBarA':
                                return <NavigationBarA key={i} fields={section.fields}/>

                            //Footer A
                            case 'footerA':
                                return <FooterA key={i} fields={section.fields}/>

                            //Banner A
                            case 'bannerA':
                                return <BannerA key={i} fields={section.fields}/>

                            //Card Area A
                            case 'cardAreaA':
                                return <CardAreaA key={i} fields={section.fields}/>

                            //TextArea A
                            case 'textAreaA':
                                return <TextAreaA key={i} fields={section.fields}/>

                            //Grid Area A
                            case 'gridAreaA':
                                return <GridAreaA key={i} fields={section.fields}/>

                            //Map Area A
                            case 'mapAreaA':
                                return <MapAreaA key={i} fields={section.fields}/>

                        ///// TEMPLATE B /////

                            //Navigation Bar B
                            case 'navigationBarB':
                                return <NavigationBarB key={i} fields={section.fields}/>

                            //Footer B
                            case 'footerB':
                                return <FooterB key={i} fields={section.fields}/>

                            //Gallery B
                            case 'galleryB':
                                return <GalleryB key={i} fields={section.fields}/>

                            //Card Area B
                            case 'cardAreaB':
                                return <CardAreaB key={i} fields={section.fields}/>

                            //Banner B
                            case 'bannerB':
                                return <BannerB key={i} fields={section.fields}/>

                            //Form Area B
                            case 'formAreaB':
                                return <FormAreaB key={i} fields={section.fields}/>

                            //Tabbed Area B
                            case 'tabbedAreaB':
                                return <TabbedAreaB key={i} fields={section.fields}/>

                            //Grid Area B
                            case 'gridAreaB':
                                return <GridAreaB key={i} fields={section.fields}/>

                        ///// TEMPLATE C /////
                            //Gallery C
                            case 'galleryC':
                                return <GalleryC key={i} fields={section.fields} sys={section.sys}/>

                            //Footer C
                            case 'footerC':
                                return <FooterC key={i} fields={section.fields}/>

                            //Banner C
                            case 'bannerC':
                                return <BannerC key={i} fields={section.fields}/>

                            //Text Area C
                            case 'textAreaC':
                                return <TextAreaC key={i} fields={section.fields}/>

                            //Navigation Bar C
                            case 'navigationBarC':
                                return <NavigationBarC key={i} fields={section.fields}/>

                            //Grid Area C
                            case 'gridAreaC':
                                return <GridAreaC key={i} fields={section.fields}/>

                            //Card Area C
                            case 'cardAreaC':
                                return <CardAreaC key={i} fields={section.fields}/>

                            //Map Area C
                            case 'mapAreaC':
                                return <MapAreaC key={i} fields={section.fields}/>

                        ///// TEMPLATE D /////
                            //Footer D
                            case 'footerD':
                                return <FooterD key={i} fields={section.fields}/>

                            default :
                                return ""
                        }
                    })
                }
            </div>
        );
    }
}

export default page;
